import "./content_item.scss";

export default function ContentItem(props) {
    const date = new Date(props.timestamp);

    const text = props.content.map((contentItem) => {
        if (typeof contentItem === 'string') {
            return contentItem;
        }
        else {
            return JSON.stringify(contentItem);
        }
    }).join('\n');

    return (
        <div className="reviewer-feedback-content-item">
            <div className="reviewer-feedback-content-item-header">
                <span className="reviewer-feedback-content-item-header-title">{props.title}</span>
                <span className="reviewer-feedback-content-item-header-time">{date.toString()}</span>
            </div>
            <div className="reviewer-feedback-content-item-body">
                <span>{text}</span>
            </div>
        </div>
    );
}
