import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import RecordTable from "../../shared/RecordTable/RecordTable";
import * as extract from "../../../modules/extractFromModel";
import * as api from "../../../modules/api";

import "./edit_agreements.scss";
import LoadingBox from "../../shared/LoadingBox/LoadingBox";
import FileDropZone from "../../shared/FileDropZone/FileDropZone";

export default function EditAgreements(props) {
  const wealthManagementColumnName = `/agreement/Client Agreement & Billing Details/wealth_management_service_type_(previously_"advisory")`;

  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modelDefs, setModelDefs] = useState(null);
  const [advisory, setAdvisory] = useState([]);
  const [brokerage, setBrokerage] = useState([]);
  const [commissionable, setCommissionable] = useState([]);
  const [profservices, setProfServices] = useState([]);

  // This allows delaying the api call until after the ui has been re-rendered with a placeholder.
  const [newRecordRequest, setNewRecordRequest] = useState(null);

  // forgive me for this- these should really be set up with a global store like Redux
  const [showFlyOut, setShowFlyOut] = useState(false);
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    if (newRecordRequest === null) return;

    api.createNewAccount(newRecordRequest)
      .then(res => {
        const isNotPlaceholder = (item) => !item.isPlaceholder;

        if (props.tab === 'advisory') { 
          const placeholderRemoved = advisory.filter(isNotPlaceholder);
          res.body.record[wealthManagementColumnName] = "Advisory";
          setAdvisory([res.body.record, ...placeholderRemoved]);
        }
        else if (props.tab === 'brokerage') { 
          const placeholderRemoved = brokerage.filter(isNotPlaceholder);
          res.body.record[wealthManagementColumnName] = "Brokerage";
          setBrokerage([res.body.record, ...placeholderRemoved]);
        }
        else if (props.tab === 'commissionable') { 
          const placeholderRemoved = commissionable.filter(isNotPlaceholder);
          res.body.record[wealthManagementColumnName] = "Commissionable";
          setCommissionable([res.body.record, ...placeholderRemoved]);
        }
        else if (props.tab === 'profservices') { 
          const placeholderRemoved = profservices.filter(isNotPlaceholder);
          res.body.record[wealthManagementColumnName] = "Professional Services";
          setProfServices([res.body.record, ...placeholderRemoved]);
        }
      })
      .catch(_ => console.error("Failed to create new record"))
      .then(() => setNewRecordRequest(null));
  }, [newRecordRequest]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function getAgreements() {
      try {
        const modelDefs = await extract.getModelAgreements();
        setModelDefs(modelDefs);

        const accounts = await api.getAccounts();
        setAdvisory(accounts.filter(account => !account[wealthManagementColumnName] || account[wealthManagementColumnName].toLowerCase() === 'advisory'));
        setBrokerage(accounts.filter(account => account[wealthManagementColumnName] && account[wealthManagementColumnName].toLowerCase() === 'brokerage'));
        setCommissionable(accounts.filter(account => account[wealthManagementColumnName] && account[wealthManagementColumnName].toLowerCase() === 'commissionable'));
        setProfServices(accounts.filter(account => account[wealthManagementColumnName] && account[wealthManagementColumnName].toLowerCase() === 'professional services'));

        setLoading(false);
      }
      catch (fetchError) {
        console.error('[EDIT_AGREEMENTS]', fetchError);
        setError('Error retrieving agreement information');
      }
    }

    getAgreements();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function getFileHistory() {
      try {
        const fileHistory = await api.getFileHistory();
        if (!Array.isArray(fileHistory.body.records)) {
          setAttachments([]);
          return;
        }

        setAttachments(fileHistory.body.records.filter(file => file.category === 'attachment').sort((a, b) => {
          if (a.captured > b.captured) {
            return -1;
          }
          if (a.captured < b.captured) {
            return 1;
          }
          return 0;
        }));
      }
      catch (fetchError) {
        console.error('[EDIT_AGREEMENTS]', fetchError);
        setError('Error retrieving file history information');
      }
    }

    getFileHistory();
  }, []);

  let bodyMarkup;
  if (error) {
    bodyMarkup = (
      <div>
        {error}
      </div>
    )
  }
  else if (!loading) {
    let data = [];
    if (props.tab === 'advisory') {
      data = advisory;
    }
    else if (props.tab === 'brokerage') {
      data = brokerage;
    }
    else if (props.tab === 'commissionable') {
      data = commissionable;
    }
    else if (props.tab === 'profservices') {
      data = profservices;
    }
    else {
      data = [];
    }

    bodyMarkup = (
      <RecordTable
        // props for controls
        canAddRecords={true}
        addRecord={async () => {
          const WMST = "/agreement/Client Agreement & Billing Details/wealth_management_service_type_(previously_\"advisory\")";
          
          const placeholder = { isPlaceholder: true,  transitionStatus: 'updating' };
          const newRecordReq = {};

          if (props.tab === 'advisory') { 
            setAdvisory([placeholder, ...advisory]);
            newRecordReq[WMST] = "Advisory";
          }
          else if (props.tab === 'brokerage') { 
            setBrokerage([placeholder, ...brokerage]);
            newRecordReq[WMST] = "Brokerage";
          }
          else if (props.tab === 'commissionable') { 
            setCommissionable([placeholder, ...commissionable]);
            newRecordReq[WMST] = "Commissionable";
          }
          else if (props.tab === 'profservices') { 
            setProfServices([placeholder, ...profservices]);
            newRecordReq[WMST] = "Professional Services";
          }
          else {
            console.error('[EDIT_AGREEMENTS] No applicable tab for creating a record');
            return;
          }
          
          setNewRecordRequest(newRecordReq);
        }}
        updateTabs={(recordUpdates) => {
          for (const update of recordUpdates) {
            // skip if there isn't a tab change
            if (update.fromTab === update.toTab) {
              continue;
            }

            // we'll need to take the record from a tab...
            let rowData;
            if (update.fromTab === '' || update.fromTab === undefined || update.fromTab.toLowerCase() === 'advisory') {
              const index = advisory.findIndex(record => record.record_id === update.recordId);
              rowData = advisory[index];
              advisory.splice(index, 1);
              setAdvisory([...advisory]);
            }
            else if (update.fromTab.toLowerCase() === 'brokerage') {
              const index = brokerage.findIndex(record => record.record_id === update.recordId);
              rowData = brokerage[index];
              brokerage.splice(index, 1);
              setBrokerage([...brokerage]);
            }
            else if (update.fromTab.toLowerCase() === 'commissionable') {
              const index = commissionable.findIndex(record => record.record_id === update.recordId);
              rowData = commissionable[index];
              commissionable.splice(index, 1);
              setCommissionable([...commissionable]);
            }
            else if (update.fromTab.toLowerCase() === 'profservices') {
              const index = profservices.findIndex(record => record.record_id === update.recordId);
              rowData = profservices[index];
              profservices.splice(index, 1);
              setProfServices([...profservices]);
            }
            else {
              console.error(`[EDIT_AGREEMENTS] No applicable fromTab for ${update.fromTab.toLowerCase()}`);
            }

            // ...and put it into another
            if (update.toTab === '' || update.toTab === undefined || update.toTab.toLowerCase() === 'advisory') {
              setAdvisory([rowData, ...advisory]);
            }
            else if (update.toTab.toLowerCase() === 'brokerage') {
              setBrokerage([rowData, ...brokerage]);
            }
            else if (update.toTab.toLowerCase() === 'commissionable') {
              setCommissionable([rowData, ...commissionable]);
            }
            else if (update.toTab.toLowerCase() === 'profservices') {
              setProfServices([rowData, ...profservices]);
            }
            else {
              console.error(`[EDIT_AGREEMENTS] No applicable toTab for ${update.fromTab.toLowerCase()}`);
            }
          }
        }}
        canDeleteRecords={true}
        canSendRecordsToCas={false}
        canPromoteRecords={true}
        canEditPromotedRecords={false}
        canDemoteRecords={false}
        canEditDemotedRecords={true}
        canBulkEditRecords={true}

        tab={props.tab}
        isAccount={true}
        data={data}
        modelDefs={modelDefs}
        setShowFlyOut={setShowFlyOut}
        attachments={attachments}
        setAttachments={setAttachments}
        flags={props.flags} />
    );
  }
  else {
    bodyMarkup = <LoadingBox subMessage="This may take up to a minute for many records." />
  }

  return (
    <div id="edit-agreements-container">
      <div id="agreement-selection-tab">
        <Link to={`/advisor/editagreements/advisory`} className={`agreement-tab clickable ${props.tab === 'advisory' ? 'active-tab' : ''}`}>
          <div>
            <p>Advisory Financial Accounts</p>
          </div>
        </Link>
        <Link to={`/advisor/editagreements/brokerage`} className={`agreement-tab clickable ${props.tab === 'brokerage' ? 'active-tab' : ''}`}>
          <div>
            <p>Brokerage Financial Accounts</p>
          </div>
        </Link>
        <Link to={`/advisor/editagreements/commissionable`} className={`agreement-tab clickable ${props.tab === 'commissionable' ? 'active-tab' : ''}`}>
          <div>
            <p>Commissionable Financial Accounts</p>
          </div>
        </Link>
        <Link to={`/advisor/editagreements/profservices`} className={`agreement-tab clickable ${props.tab === 'profservices' ? 'active-tab' : ''}`}>
          <div>
            <p>Professional Services Agreements</p>
          </div>
        </Link>
      </div>
      <div id="edit-agreements-table-container">
        {bodyMarkup}
        <div id="edit-contacts-fly-out" className={showFlyOut ? 'show-fly-out' : 'hide-fly-out'}>
          <div id="edit-contacts-fly-out-header" className={showFlyOut ? 'show-fly-out-header' : 'hide-fly-out-header'}>
            <div id="retract-button" onClick={() => { setShowFlyOut(false) }}>
              <i className="fas fa-arrow-right fa-1x"></i>
            </div>
            <span id="fly-out-label">Uploaded Files</span>
          </div>
          <div id="edit-contacts-fly-out-body" style={{ display: showFlyOut ? 'initial' : 'none' }}>
            <FileDropZone customClasses={["file-attach-drop-zone"]} onUpload={(newAttachment) => { setAttachments([newAttachment, ...attachments]) }} />
          </div>
          <div id="edit-contacts-fly-out-footer" style={{ display: showFlyOut ? 'initial' : 'none' }}>
            {attachments.map((attachment) => {
              return (
                <div className="fly-out-file-item">
                  <span className="fly-out-file-date">{attachment.captured}</span>
                  <span className="fly-out-file-name">{attachment.filename}</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
