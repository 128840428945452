import { Switch, Route, useRouteMatch } from "react-router-dom";

import NavigationItem from "../../components/Navbar/NavigationItem/NavigationItem";
import SignOutNav from "../../components/shared/SignOutNav/SignOutNav";
import EditAccounts from "../../components/Reviewer/EditAccounts/EditAccounts";
import EditContacts from "../../components/Reviewer/EditContacts/EditContacts";
import ProjectDashboard from "../../components/shared/ProjectDashboard/ProjectDashboard";
import ProjectSettings from "../../components/Reviewer/ProjectSettings/ProjectSettings";
import ImportData from "../../components/shared/ImportData/ImportData";
import Header from "../../components/Header/Header";

import "./reviewer.scss";

export default function Reviewer(props) {
  if (!props.flags.noauth) {
    const authToken = localStorage.getItem('authToken');
    const payload = authToken === '' || authToken === null ? null : JSON.parse(atob(authToken.split('.')[1]));
  
    if (!payload.reviewer) {
      console.log('Detected that this user is not a reviewer. Redirecting to /advisor');
      window.location.replace('/advisor');
    }
  }

  const match = useRouteMatch();

  return (
    <div id="reviewer-container">
      <nav id="left-nav-reviewer">
        <div id="corner-logo">
          <h1 className="aim-logo">AIM</h1>
          <span className="aim-logo-subtext">Advisor Intake Manager</span>
        </div>
        <div id="nav-link-container">
          <NavigationItem
            active={window.location.pathname === '/reviewer' || window.location.pathname === '/reviewer/'}
            icon="fa-chart-line"
            text="Dashboard"
            href="/reviewer" />
          <NavigationItem
            active={window.location.pathname.startsWith('/reviewer/settings')}
            icon="fa-cog"
            text="Project Settings"
            href="/reviewer/settings" />
          <NavigationItem
            active={window.location.pathname.startsWith('/reviewer/import')}
            icon="fa-file-import"
            text="Import Data"
            href="/reviewer/import" />
          <NavigationItem
            active={window.location.pathname.startsWith('/reviewer/editcontacts')}
            icon="fa-user-circle"
            text="Contacts"
            href="/reviewer/editcontacts" />
          <NavigationItem
            active={window.location.pathname.startsWith('/reviewer/editagreements')}
            icon="fa-wallet"
            text="Financial Accounts"
            href="/reviewer/editagreements" /> 
          <hr />
          <SignOutNav />
        </div>
        <div id="powered-by-ht-logo-container">
          <img id="powered-by-ht-logo" src="/images/PoweredByHT.png" alt="aim logo"/>
        </div>
      </nav>
      <div id="reviewer-content-body">
        <Header />
        <Switch>
          <Route path={`${match.url}/import`}>
            <ImportData />
          </Route>
          <Route path={`${match.url}/editagreements/advisory`}>
            <EditAccounts flags={props.flags} tab="advisory" />
          </Route>
          <Route path={`${match.url}/editagreements/brokerage`}>
            <EditAccounts flags={props.flags} tab="brokerage" />
          </Route>
          <Route path={`${match.url}/editagreements/commissionable`}>
            <EditAccounts flags={props.flags} tab="commissionable" />
          </Route>
          <Route path={`${match.url}/editagreements/profservices`}>
            <EditAccounts flags={props.flags} tab="profservices" />
          </Route>
          <Route path={`${match.url}/editagreements`}>
            <EditAccounts flags={props.flags} tab="advisory" />
          </Route>
          <Route path={`${match.url}/settings`}>
            { props.flags.settings ? <ProjectSettings /> : undefined }
          </Route>
          <Route path={`${match.url}/editcontacts`}>
            <EditContacts flags={props.flags} />
          </Route>
          <Route path={`${match.url}`}>
            { props.flags.dashboard ? <ProjectDashboard /> : undefined }
          </Route>
        </Switch>
      </div>
    </div>
  );
}
