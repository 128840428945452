import ViewNotesButton from "./ViewNotesButton/ViewNotesButton";

import "./feedback_cell_renderer.scss";

export default function FeedbackCellRenderer({displayModal, props}) {
  const hasFeedback = !!props.data.hasFeedback;

  const onClick = () => {
    displayModal(props.data.record_id);
  };

  let cellMarkup = null;
  if (hasFeedback) {
    cellMarkup = (
      <div className="view-notes-cell">
        <ViewNotesButton text="View" onClick={onClick} />
      </div>
    );
  }

  return cellMarkup;
}
