import { useState, useEffect } from "react";

import RecordTable from "../../shared/RecordTable/RecordTable";
import LoadingBox from "../../shared/LoadingBox/LoadingBox";
import * as extract from "../../../modules/extractFromModel";
import * as api from "../../../modules/api";

import "./edit_contacts.scss";

export default function EditContacts(props) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [modelDefs, setModelDefs] = useState(null);

  // This allows delaying the api call until after the ui has been re-rendered with a placeholder.
  const [newRecordRequest, setNewRecordRequest] = useState(null);

  useEffect(() => {
    if (newRecordRequest === null) return;

    // Don't await, let it happen async so things don't lock up.
    api.createNewContact(newRecordRequest)
      .then(res => {
        const placeholderRemoved = contacts.filter(elem => !elem.isPlaceholder);
        setContacts([res.body.record, ...placeholderRemoved]);
      })
      .catch(_ => console.error("Failed to create new record"))
      .then(() => setNewRecordRequest(null));
  }, [newRecordRequest]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    async function getContacts() {
      try {
        const modelDefs = await extract.getModelClients();
        setModelDefs(modelDefs);
        
        const clients = await api.getContacts();

        // setContacts(clients.filter(contact => contact.status === 'promoted' || contact.status === 'committed' || contact.status === 'demoted'));
        setContacts(clients);
        setLoading(false);
      }
      catch (fetchError) {
        console.error(fetchError);
        setError('Error retrieving contact information');
      }
    }

    getContacts();
  }, []);

  let bodyMarkup;
  if (error) {
    bodyMarkup = (
      <div>
        {error}
      </div>
    )
  }
  else if (loading) {
    bodyMarkup = <LoadingBox subMessage="This may take up to a minute for many records." />
  }
  else {
    bodyMarkup = (
      <RecordTable
        canAddRecords={true}
        addRecord={async () => {
          // Not prepopulating anything 
          const placeholder = { isPlaceholder: true, transitionStatus: 'updating' };
          setContacts([placeholder, ...contacts]);
          setNewRecordRequest({/* not prepopulating anything */});
        }}
        canDeleteRecords={true}
        canCommitRecords={true}
        canPromoteRecords={true}
        canEditPromotedRecords={true}
        canDemoteRecords={true}
        canEditDemotedRecords={true}
        canBulkEditRecords={false} // there are no valid "bulk editable" fields for contacts

        isAccount={false}
        isReviewer={true}
        data={contacts}
        modelDefs={modelDefs}
        flags={props.flags} />
    );
  }

  return (
    <div id="reviewer-contacts-container">
      {bodyMarkup}
    </div>
  );
}
