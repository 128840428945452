export let ALL_PATHS = {};

const CHUNK_SIZE = 500;

async function init() {
    const paths = await fetch('/paths.json');
    ALL_PATHS = await paths.json();
}

function chunkArray(data, chunkSize) {
    if (!chunkSize > 0) {
        // If invalid chunkSize given, just return chunkSize == data.length
        return [[...data]];
    }

    const chunks = [];

    let cursor = 0;
    while (cursor < data.length) {
        const start = cursor;
        const end = Math.min(cursor + chunkSize, data.length);

        chunks.push(data.slice(start, end));
        cursor += chunkSize;
    }

    return chunks;
}

/*
  Core call
*/
async function callApi(url, method, body, queryStringParams) {
    try {
        const authToken = localStorage.getItem('authToken');
        const headers = { 'Content-Type': 'application/json' };

        if (authToken) {
            headers.Authorization = authToken;
        }
    
        let queryString;
        if (queryStringParams) {
            queryString = '?' + Object.entries(queryStringParams).map(q => `${q[0]}=${q[1]}`).join('&');
        }

        const params = {
            method,
            mode: 'cors',
            headers,
        };

        if (body) {
            params.body = JSON.stringify(body);
        }

        const response = await fetch(`${url}${ queryString || '' }`, params);
        const jsonResponse = await response.json();

        /*
            If we get a 401 we should redirect to the root page
        */
        if (response.status === 401) {
            window.location.replace("/");
        }

        return ({ status: response.status, ok: response.ok, body: jsonResponse });
    }
    catch (fetchError) {
        const message = `error when performing ${method} ${url}: ${fetchError}`;
        console.error('[API]', message);
        throw new Error(message);
    }
}

/*
  Convenience functions for HTTP methods
*/
async function get(path, queryStringParams) {
    return await callApi(path, 'GET', undefined, queryStringParams);
}

async function post(path, body) {
    return await callApi(path, 'POST', body);
}

async function put(path, body) {
    return await callApi(path, 'PUT', body);
}

/*
  Support functions for "chunked" API calls
*/

async function updateAccounts(data) {
    return await put(ALL_PATHS.updateAccountPath, data);
}

async function updateContacts(data) {
    return await put(ALL_PATHS.updateContactPath, data);
}

async function promoteAccounts(data) {
    return await post(ALL_PATHS.promoteAccountPath, data);
}

async function promoteContacts(data) {
    return await post(ALL_PATHS.promoteContactPath, data);
}

async function demoteAccounts(data) {
    return await post(ALL_PATHS.demoteAccountPath, data);
}

async function demoteContacts(data) {
    return await post(ALL_PATHS.demoteContactPath, data);
}

async function submitAccounts(data) {
    return await post(ALL_PATHS.submitAccountPath, data);
}

async function submitContacts(data) {
    return await post(ALL_PATHS.submitContactPath, data);
}

async function getAccountUpdates(data) {
    return await post(ALL_PATHS.getAccountUpdatesPath, data);
}

async function getContactUpdates(data) {
    return await post(ALL_PATHS.getContactUpdatesPath, data);
}

/*
  API calls

  These perform core actions on records. RecordTable passes however many rows they'd like to
  perform actions on and this API layer "chunks" it as needed. Currently the RecordTable
  needs to understand that its requests may be "chunked" which isn't great for encapsulation.
*/

export async function updateRows(isAccount, rows) {
    await init();
    const callApiUpdate = isAccount ? updateAccounts : updateContacts;

    const requests = [];
    const chunks = chunkArray(rows, CHUNK_SIZE);
    for (const chunk of chunks) {
        requests.push(callApiUpdate(chunk));
    }

    return await Promise.all(requests);
}

export async function promoteRows(isAccount, rows) {
    await init();
    const callApiUpdate = isAccount ? promoteAccounts : promoteContacts;

    const requests = [];
    const chunks = chunkArray(rows, CHUNK_SIZE);
    for (const chunk of chunks) {
        requests.push(callApiUpdate(chunk));
    }

    return await Promise.all(requests);
}

export async function demoteRows(isAccount, rowsWithFeedback) {
    await init();
    const callApiUpdate = isAccount ? demoteAccounts : demoteContacts;

    const requests = [];
    const chunks = chunkArray(rowsWithFeedback, CHUNK_SIZE);
    for (const chunk of chunks) {
        requests.push(callApiUpdate(chunk));
    }

    return await Promise.all(requests);
}

export async function submitRows(isAccount, rows) {
    await init();
    const callApiUpdate = isAccount ? submitAccounts : submitContacts;

    const requests = [];
    const chunks = chunkArray(rows, CHUNK_SIZE);
    for (const chunk of chunks) {
        requests.push(callApiUpdate(chunk));
    }

    return await Promise.all(requests);
}


export async function getRecordUpdates(isAccount, recordIds) {
    await init();
    const callApiUpdate = isAccount ? getAccountUpdates : getContactUpdates;

    const requests = [];
    const chunks = chunkArray(recordIds, CHUNK_SIZE);
    for (const chunk of chunks) {
        requests.push(callApiUpdate(chunk));
    }

    return await Promise.all(requests);
}

/*
  "Official" API endpoints
*/

export async function getAccounts() {
    await init();
    let allAccounts = [];
    let response;
    do {
        if (response && response.body.lastEvaluatedKey) {
            response = await get(`${ALL_PATHS.getAccountsPath}?lastEvaluatedKey=${response.body.lastEvaluatedKey.record_id}`);
        }
        else {
            response = await get(ALL_PATHS.getAccountsPath);
        }

        if (response.body && response.body.records) {
            allAccounts = allAccounts.concat(response.body.records);
        }
    } while (response.body.lastEvaluatedKey);

    return allAccounts;
}

export async function getContacts() {
    await init();
    let allContacts = [];
    let response;
    do {
        if (response && response.body.lastEvaluatedKey) {
            response = await get(`${ALL_PATHS.getContactsPath}?lastEvaluatedKey=${response.body.lastEvaluatedKey.record_id}`);
        }
        else {
            response = await get(ALL_PATHS.getContactsPath);
        }

        if (response.body && response.body.records) {
            allContacts = allContacts.concat(response.body.records);
        }
    } while (response.body.lastEvaluatedKey);

    return allContacts;
}

export async function uploadFiles(fileData, filename) {
    await init();
    try {
        const authToken = localStorage.getItem('authToken');
        const headers = {};

        if (authToken) {
            headers.Authorization = authToken;
        }

        const response = await fetch(`${ALL_PATHS.uploadFilesPath}/${encodeURIComponent(filename)}`,
        {
            method: 'POST',
            mode: 'cors',
            headers,
            body: fileData
        });
        const jsonResponse = await response.json();

        /*
        If we get a 401 we should redirect to the root page
        */
        if (response.status === 401) {
            window.location.replace("/");
        }

        return ({ status: response.status, ok: response.ok, body: jsonResponse });
    }
    catch (fetchError) {
        const message = `error when performing POST /ui/files: ${fetchError}`;
        console.error('[API]', message);
        throw new Error(message);
    }
}

export async function getFileHistory() {
  await init();
  return await get(ALL_PATHS.getFileHistoryPath);
}

export async function sendAccountsToCas(data) {
    await init();
    console.info(`Sending ${data.length} Accounts to CAS`);
    return await post(ALL_PATHS.sendAccountsToCasPath, data);
}

export async function sendContactsToCas(data) {
    await init();
    console.info(`Sending ${data.length} Contacts to CAS`);
    return await post(ALL_PATHS.sendContactsToCasPath, data);
}

export async function getDashboardValues() {
    await init();
    return await get(ALL_PATHS.getDashboardValuesPath);
}

export async function getPickLists() {
    await init();
    return await get(ALL_PATHS.getPickListsPath);
}

export async function getQaMetrics() {
    await init();
    return await get(ALL_PATHS.getQaMetricsPath);
}

export async function getPaths() {
    await init();
    return ALL_PATHS;
}

export async function updateSetting(data) {
    await init();
    return await put(ALL_PATHS.updateSettingsPath, data);
}

export async function getSettings() {
    await init();
    return await get(ALL_PATHS.getSettingsPath);
}

export async function assignCaaNumbers(data) {
    await init();
    return await put(ALL_PATHS.assignCaaNumbersPath, data);
}

export async function createNewAccount(data) {
    await init();
    return await put(ALL_PATHS.createNewAccountPath, data);
}

export async function createNewContact(data) {
    await init();
    return await put(ALL_PATHS.createNewContactPath, data);
}

export async function getFeedback(recordId, afterTimestamp) {
    await init();

    const queryStringParams = { recordId };

    if (afterTimestamp) {
        queryStringParams.afterTimestamp = afterTimestamp;
    }

    return await get(ALL_PATHS.getFeedback, queryStringParams);
}
