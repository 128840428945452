import NavigationItem from "./NavigationItem/NavigationItem";
import SignOutNav from "../shared/SignOutNav/SignOutNav";

import "./navbar.scss";

export default function Navbar() {
  return (
    <nav id="left-nav">
      <div id="corner-logo">
        <h1 className="aim-logo">AIM</h1>
        <span className="aim-logo-subtext">Advisor Intake Manager</span>
      </div>
      <div id="nav-link-container">
        <NavigationItem
          active={window.location.pathname === '/advisor' || window.location.pathname === '/advisor/'}
          text="Dashboard"
          icon="fa-chart-line"
          href={`/advisor`} /> 
        <NavigationItem
          active={window.location.pathname.startsWith('/advisor/import')}
          text="Import Data"
          icon="fa-file-import"
          href={`/advisor/import`} />
        <NavigationItem
          active={window.location.pathname.startsWith('/advisor/editcontacts')}
          text="Contacts"
          icon="fa-user-circle"
          href={`/advisor/editcontacts`} />
        <NavigationItem
          active={window.location.pathname.startsWith('/advisor/editagreements')}
          text="Financial Accounts"
          icon="fa-wallet"
          href={`/advisor/editagreements`} />
        <hr />
        <SignOutNav />
      </div>
      <div id="powered-by-ht-logo-container">
        <img id="powered-by-ht-logo" src="/images/PoweredByHT.png" alt="aim logo"/>
      </div>
    </nav>
  );
}
