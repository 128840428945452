import React, { useEffect, useState } from "react";

import AimModal from "../../AimModal/AimModal";
import TableButton from "../../TableButton/TableButton";
import * as api from "../../../../modules/api";
import ContentItem from "./ContentItem/ContentItem";
import LoadingBox from "../../LoadingBox/LoadingBox";

import "./reviewer_feedback_modal.scss";

export default function ReviewerFeedbackModal(props) {
  const [feedback, setFeedback] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getFeedback() {
      setLoading(true);

      try {
        const feedbackResponse = await api.getFeedback(props.recordId);

        if (feedbackResponse.status !== 200) {
          throw new Error('Non-200 status');
        }

        setFeedback(feedbackResponse.body.feedback);
      }
      catch (error) {
        console.error('Feedback request error');
      }

      setLoading(false);
    }

    // only fire off if we have a valid record id and the modal is showing
    if (props.recordId && props.show) {
      getFeedback();
    }
  }, [props.recordId, props.show]);

  let aimModalContent;
  if (loading) {
    aimModalContent = <LoadingBox subMessage="Retrieving feedback..." />
  }
  else {
    aimModalContent = (
      <>
        <div id="reviewer-feedback-modal-header">
          <span>Feedback</span>
        </div>
        <div id="reviewer-feedback-modal-body">
          {feedback.map(item => <ContentItem title={item.context} timestamp={item.timestamp} content={item.content} />)}
        </div>
        <div id="reviewer-feedback-modal-footer">
          <TableButton text="Close" onClick={props.onClose}/>
        </div>
      </>
    );
  }

  return (
    <AimModal show={props.show}>
      {aimModalContent}
    </AimModal>
  )
}
