import { useState } from "react";
import { Link } from "react-router-dom";

import FileDropZone from "../FileDropZone/FileDropZone";
import ImportOptionGroup from "./ImportOptionGroup/ImportOptionGroup";

import "./import_data.scss";

export default function ImportData(props) {
  const [activeImport, setActiveImport] = useState(false);
  const [display, setDisplay] = useState('');

  let footerMarkup;
  if (activeImport) {
    footerMarkup = (
      <>
        <div id="import-confirm-message" className="text-muted">
          <p>{display}</p>
        </div>
        <div id="import-confirm-footer-links">
          <Link to={`/advisor/editcontacts`}>View Contacts</Link>
          <Link to={`/advisor/editagreements`}>View Financial Accounts</Link>
        </div>
      </>
    );
  }

  return (
    <div id="import-tools-container">
      <div id="import-file-drop">
        <FileDropZone
          onUpload={(results) => {
            setActiveImport(true);

            if (results.category === "attachment") {
              setDisplay("Attachment uploaded successfully.");
            }
            else if (results.rows < 1) {
              setDisplay("This file contains no new rows.");
            }
            else {
              setDisplay(`This file contains ${results.rows} new rows and ${results.cols} columns. These rows include ${results.contacts} new contacts and ${results.accounts} new accounts. Note that records may take up to 30 seconds before they can be viewed in the editor.`);
            }
          }}
          customClasses={["import-data-page-drop-zone"]}
          activeProject={props.activeProject}
          userId={localStorage.getItem('user_id')} />
        <div id="import-footer">
          {footerMarkup}
        </div>
      </div>
      <div id="import-options-container">
        

        <div id="import-options-groups">
          
        </div>
      </div>
    </div>
  );
}
